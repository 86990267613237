
import Vue from "vue";
import { Component } from "vue-property-decorator";
import publications, {
  Publication as PublicationType,
} from "@/store/publications";
import Publication from "@/components/Publication.vue";

@Component({
  components: { Publication },
})
export default class Publications extends Vue {
  name = "Publications";

  get publications(): PublicationType[] {
    return publications;
  }
}
