import { render, staticRenderFns } from "./Publication.vue?vue&type=template&id=9aab3d5c&scoped=true&"
import script from "./Publication.vue?vue&type=script&lang=ts&"
export * from "./Publication.vue?vue&type=script&lang=ts&"
import style0 from "./Publication.vue?vue&type=style&index=0&id=9aab3d5c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9aab3d5c",
  null
  
)

export default component.exports