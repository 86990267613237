
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Publication as PublicationType } from "@/store/publications";

@Component
export default class Publication extends Vue {
  name = "Publication";
  @Prop() publication?: PublicationType;
  @Prop({ type: Boolean, default: false }) shortLine3?: boolean;
  @Prop({ type: Boolean, default: false }) shortAuthor?: boolean;

  KEYS = ["view", "pdf", "code", "more"];

  keys(publication: PublicationType): string[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.KEYS.filter((k) => publication[k]);
  }

  href(publication: PublicationType): string | undefined {
    if (publication.md) return `/pub/${publication.title}`;
    else if (publication.href) return publication.href;
  }

  generateLine3(pub: PublicationType): string {
    let components = [
      pub.journal + (pub.abbreviation ? ` <b>(${pub.abbreviation})</b>` : ""),
      pub.volume,
      pub.page,
      pub.year,
    ];
    if (this.shortLine3)
      components = [
        pub.abbreviation ? `<b>${pub.abbreviation}</b>` : pub.journal,
        pub.year,
      ];
    return components.filter((v) => v).join(", ");
  }

  author(publication: PublicationType): string {
    const authorStr = publication.author as string;
    if (!this.shortAuthor) return authorStr;
    const splited = authorStr.split(", ");
    if (splited.length <= 1) return authorStr;
    return splited
      .map((a) => {
        const words = a.split(" ");
        return words
          .map((w, i) => (i === words.length - 1 ? w : w.substr(0, 1)))
          .join(" ");
      })
      .join(", ");
  }
}
