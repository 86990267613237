// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Cite from "citation-js"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { mergeSort } from "sort-algorithms-js"
import { processPublications } from "@/utils/publications";

export interface Publication {
    // 以下两种任选其一
    // 1. 给出bibtex
    bibtex?: string
    // 2. 依次给出下面的条目
    author?: string
    title?: string
    journal?: string
    abbreviation?: string
    volume?: string
    page?: string
    year?: string
    line3?: string // 这里可以放html。若line3未指定则由journal、abbreviation、year等生成。
    tag?: { content: string, color: string }
    // 点击标题时指向的链接
    md?: string | (() => Promise<string | typeof import("*.md")>) // 通常是通过异步require引入一个markdown文件。（利用了markdown-loader包，实际上require markdown文件得到的就是一段html字符串，因此理论上，这里填写markdown字符串也是没有任何问题的）
    href?: string // 点击标题时跳转的页面
    // 下面的是补充链接。可以是一般的http开头的链接，也可以通过本服务器的public静态文件分发，此时路径为/public/xxx.pdf。
    view?: string // 例如链接到IEEE Xplore等网站的论文页面
    pdf?: string // 可以直接下载pdf的链接
    code?: string // 通常是Github链接
    more?: string // 其他内容
    img?: string // 预留接口，目前不支持显示
}

interface CSL {
    author: { given: string, family: string }[]
    title: string
    "container-title": string,
    issued: {
        "date-parts": number[]
    }
}

function parseBibtex(bibtex: string): Publication {
    const csl: CSL = new Cite(bibtex).format("data", { format: "object" })[0]
    return {
        author: csl.author.map(({ family, given }) => `${given ? given + " " : ""}${family}`).join(", "),
        title: csl.title,
        journal: csl["container-title"],
        year: String(csl.issued["date-parts"][0])
    }
}

export function transformBibtex(publications: Publication[]): void {
    for (const v of publications) {
        if (v.bibtex) {
            const bibRes = parseBibtex(v.bibtex)
            for (const key in bibRes) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (!v[key]) v[key] = bibRes[key]
            }
        }
    }
}

function state() {
    const books: Publication[] = [
        {
            author: "Qionghai Dai, Yue Gao",
            title: "Hypergraph Computation",
            journal: "Springer",
            volume: "ISBN: 978-981-99-0184-5",
            year: "2023",
            tag: { content: "BOOK", color: "primary" },
            href: "https://link.springer.com/book/10.1007/978-981-99-0185-2",
        },        
        {
            author: "Yue Gao, Qionghai Dai",
            title: "View-based 3-D Object Retrieval",
            journal: "Elsevier",
            volume: "ISBN: 978-0-12-802419-5",
            year: "2014",
            tag: { content: "BOOK", color: "primary" },
            href: "https://www.sciencedirect.com/book/9780128024195/view-based-3-d-object-retrieval"
        },
        {
            author: "Rongrong Ji, Yue Gao, Ling-Yu Duan, Hongxun Yao, Qionghai Dai",
            title: "Learning-Based Local Visual Representation and Indexing",
            journal: "Elsevier, ISBN: 978-0-12-802409-6",
            year: "2015",
            tag: {
                content: "BOOK",
                color: "primary"
            }
        },
    ]
    const topJournals: Publication[] = [
        {
            author: "Xiangmin Han, Rundong Xue, Shaoyi Du, Yue Gao",
            title: "Inter-Intra high-order Brain Network for ASD Diagnosis via Functional MRIs",
            journal: "International Conference on Medical Image Computing and Computer-Assisted Intervention",
            year: "2024",
            // href: "",
        },
        {
            author: "Huijian Zhou, Zhiqiang Tian, Xiangmin Han, Shaoyi Du, Yue Gao",
            title: "ccRCC Metastasis Prediction via Exploring High-Order Correlations on Multiple WSIs",
            journal: "International Conference on Medical Image Computing and Computer-Assisted Intervention",
            year: "2024",
            // href: "",
        },
        {
            author: "Yue Gao, Shuyi Ji, Xiangmin Han, Qionghai Dai",
            title: "Hypergraph Computation",
            journal: "Engineering",
            year: "2024",
            href: "https://www.sciencedirect.com/science/article/pii/S2095809924002510",
        },
        {
            author: "Juncheng Mu, Lin Bie, Shaoyi Du, Yue Gao",
            title: "ColorPCR: Color Point Cloud Registration with Multi-Stage Geometric-Color Fusion",
            journal: "Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition",
            year: "2024",
            href: "https://openaccess.thecvf.com/content/CVPR2024/html/Mu_ColorPCR_Color_Point_Cloud_Registration_with_Multi-Stage_Geometric-Color_Fusion_CVPR_2024_paper.html",
        },
        {
            author: "Siqi Li, Zhikuan Zhou, Zhou Xue, Yipeng Li, Shaoyi Du, Yue Gao",
            title: "3D Feature Tracking via Event Camera",
            journal: "Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition",
            year: "2024",
            href: "https://openaccess.thecvf.com/content/CVPR2024/html/Li_3D_Feature_Tracking_via_Event_Camera_CVPR_2024_paper.html",
        },
        {
            author: "Jielong Yan, Yifan Feng, Shihui Ying, Yue Gao",
            title: "Hypergraph Dynamic System",
            journal: "International Conference on Learning Representations",
            year: "2024",
            href: "https://openreview.net/forum?id=NLbRvr840Q",
        },
        {
            author: "Yifan Feng, Yihe Luo, Shihui Ying, Yue Gao",
            title: "LightHGNN: Distilling Hypergraph Neural Networks into MLPs for 100x Faster Inference",
            journal: "International Conference on Learning Representations",
            year: "2024",
            href: "https://openreview.net/forum?id=lHasEfGsXL",
        },
        {
            author: "Yue Gao, Jiaxuan Lu, Siqi Li, Yipeng Li, Shaoyi Du",
            title: "Hypergraph-based Multi-View ActionRecognition using Event Cameras",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2024",
            href: "https://ieeexplore.ieee.org/abstract/document/10480584",
        },
        {
            author: "Bohua Wang, Zhiqiang Tian, Aixue Ye, Feng Wen, Shaoyi Du, Yue Gao",
            title: "Generative Variational-Contrastive Learning for Self-supervised Point Cloud Representation",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2024",
            href: "https://ieeexplore.ieee.org/abstract/document/10475594",
        },
        {
            author: "Yifan Feng, Jiashu Han, Shihui Ying, Yue Gao",
            title: "Hypergraph Isomorphism Computation",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2024",
            href: "https://ieeexplore.ieee.org/abstract/document/10398457",
        },
        {
            author: "Yifan Feng, Shuyi Ji, Yu-Shen Liu, Shaoyi Du, Qionghai Dai, Yue Gao",
            title: "Hypergraph-Based Multi-Modal Representation for Open-Set 3D Object Retrieval",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2023",
            href: "https://ieeexplore.ieee.org/abstract/document/10319392",
        },
        {
            author: "吉书仪, 魏宇轩, 戴琼海, 高跃",
            title: "面向大规模数据的高效超图神经网络",
            journal: "中国科学: 信息科学",
            year: "2023",
            href: "https://www.sciengine.com/doi/pdfView/97F50B933E6B4FA0BF0D7B49A32469DD",
        },
        {
            bibtex: `@ARTICLE{yao2023hunter,
                title={Hunter: Exploring High-Order Consistency for Point Cloud Registration with Severe Outliers},
                author={Yao, Runzhao and Du, Shaoyi and Cui, Wenting and Ye, Aixue and Wen, Feng and Zhang, Hongbo and Tian, Zhiqiang and Gao, Yue},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                year={2023},
                publisher={IEEE}}
            `,
            href: "https://ieeexplore.ieee.org/abstract/document/10246849"
        },
        {
            author: "Yu Jiang, Cong Hua, Yifan Feng, Yue Gao",
            title: "Hierarchical Set-to-set Representation for 3D Cross-modal Retrieval",
            journal: "IEEE Transactions on Neural Networks and Learning Systems",
            year: "2023",
            href: "https://ieeexplore.ieee.org/abstract/document/10316653",
        },
        {
            author: "Hao Zhong, Yubo Zhang, Chenggang Yan, Zuxing Xuan, Ting Yu, Ji Zhang, Shihui Ying, Yue Gao",
            title: "Penalized Flow Hypergraph Local Clustering",
            journal: "IEEE Transactions on Knowledge and Data Engineering",
            year: "2023",
            href: "https://ieeexplore.ieee.org/abstract/document/10262346",
        },
        {
            author: "Yue Gao, Jiaxuan Lu, Siqi Li, Nan Ma, Shaoyi Du, Yipeng Li, Qionghai Dai",
            title: "Action Recognition and Benchmark Using Event Cameras",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2023",
            href: "https://ieeexplore.ieee.org/document/10198747",
        },    
        {
            author: "Shengrui Li, Yining Zhao, Jun Zhang, Ting Yu, Ji Zhang, Yue Gao",
            title: "High-Order Correlation-Guided Slide-Level Histology Retrieval with Self-Supervised Hashing",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2023",
            href: "https://ieeexplore.ieee.org/document/10107814"
        },
        {
            bibtex: `@ARTICLE{9962797,
                author={Gao, Yue and Li, Siqi and Li, Yipeng and Guo, Yandong and Dai, Qionghai},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={SuperFast: 200× Video Frame Interpolation via Event Camera},
                year={2022},
                volume={},
                number={},
                pages={1-17},
                doi={10.1109/TPAMI.2022.3224051}}
              `,
            href: "https://ieeexplore.ieee.org/abstract/document/9962797"
        },
        {
            author: "Hai Wan, Xinwei Zhang, Yubo Zhang, Xibin Zhao, Shihui Ying, Yue Gao",
            title: "Structure Evolution on Manifold for Graph Learning",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2022",
            href: "https://ieeexplore.ieee.org/document/9966846",
        },
        {
            bibtex: `@ARTICLE{10018934,
                author={Lu, Jiaxuan and Wan, Hai and Li, Peiyan and Zhao, Xibin and Ma, Nan and Gao, Yue},
                journal={IEEE Transactions on Image Processing}, 
                title={Exploring High-Order Spatio-Temporal Correlations From Skeleton for Person Re-Identification}, 
                year={2023},
                volume={32},
                number={},
                pages={949-963},
                doi={10.1109/TIP.2023.3236144}}
                `,
          href: "https://ieeexplore.ieee.org/document/10018934"
        },
        {
            bibtex: `@ARTICLE{9903546,
                author={Di, Donglin and Zou, Changqing and Feng, Yifan and Zhou, Haiyan and Ji, Rongrong and Dai, Qionghai and Gao, Yue},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={Generating Hypergraph-Based High-Order Representations of Whole-Slide Histopathological Images for Survival Prediction},
                year={2023},
                volume={45},
                number={5},
                pages={5800-5815},
                doi={10.1109/TPAMI.2022.3209652}}
              `,
            href: "https://ieeexplore.ieee.org/abstract/document/9903546"
        },
        {
            bibtex: `@ARTICLE{9795251,
                author={Gao, Yue and Feng, Yifan and Ji, Shuyi and Ji, Rongrong},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={HGNN$^+$: General Hypergraph Neural Networks},
                year={2022},  volume={},  number={},  pages={1-18},  doi={10.1109/TPAMI.2022.3182052}}`,
            href: "https://ieeexplore.ieee.org/document/9795251"
        },
        {
            bibtex: `@ARTICLE{9756344,
                author={Zhang, Yubo and Ji, Shuyi and Zou, Changqing and Zhao, Xibin and Ying, Shihui and Gao, Yue},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={Graph Learning on Millions of Data in Seconds: Label Propagation Acceleration on Graph using Data Distribution},
                year={2022},  volume={},  number={},  pages={1-1},  doi={10.1109/TPAMI.2022.3166894}}`,
            href: "https://ieeexplore.ieee.org/document/9756344"
        },
        {
            bibtex: `@ARTICLE{9705149,
                author={Wang, Yujie and Yan, Chenggang and Feng, Yutong and Du, Shaoyi and Dai, Qionghai and Gao, Yue},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={STORM: Structure-based Overlap Matching for Partial Point Cloud Registration},
                year={2022},
                volume={},
                number={},
                pages={1-1},
                doi={10.1109/TPAMI.2022.3148308}
                }`,
            href: "https://ieeexplore.ieee.org/document/9705149"
        },
        {
            bibtex: `@ARTICLE{9462521,
                author={Zhang, Xuancheng and Ma, Rui and Zou, Changqing and Zhang, Minghao and Zhao, Xibin and Gao, Yue},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={View-Aware Geometry-Structure Joint Learning for Single-View 3D Shape Reconstruction},
                year={2021},
                volume={},
                number={},
                pages={1-1},
                doi={10.1109/TPAMI.2021.3090917}}`,
            href: "https://ieeexplore.ieee.org/document/9462521/"
        },
        {
            bibtex: `@ARTICLE{9354594,
                author={Fan, Haoyi and Zhang, Fengbin and Wei, Yuxuan and Li, Zuoyong and Zou, Changqing and Gao, Yue and Dai, Qionghai},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={Heterogeneous Hypergraph Variational Autoencoder for Link Prediction},
                year={2021},
                volume={},
                number={},
                pages={1-1},
                doi={10.1109/TPAMI.2021.3059313}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9354594/"
        },
        {
            bibtex: `@ARTICLE{9007489,
                author={Yan, Chenggang and Gong, Biao and Wei, Yuxuan and Gao, Yue},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={Deep Multi-View Enhancement Hashing for Image Retrieval},
                year={2021},
                volume={43},
                number={4},
                pages={1445-1451},
                doi={10.1109/TPAMI.2020.2975798}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9007489/"
        },
        {
            bibtex: `@ARTICLE{9264674,
                author={Gao, Yue and Zhang, Zizhao and Lin, Haojie and Zhao, Xibin and Du, Shaoyi and Zou, Changqing},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={Hypergraph Learning: Methods and Practices},
                year={2020},
                volume={},
                number={},
                pages={1-1},
                doi={10.1109/TPAMI.2020.3039374}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9264674/",
        },
        {
            bibtex: `@ARTICLE{8918052,
                author={Zhou, Yiyi and Ji, Rongrong and Sun, Xiaoshuai and Su, Jinsong and Meng, Deyu and Gao, Yue and Shen, Chunhua},
                journal={IEEE Transactions on Pattern Analysis and Machine Intelligence},
                title={Plenty Is Plague: Fine-Grained Learning for Visual Question Answering},
                year={2019},
                volume={},
                number={},
                pages={1-1},
                doi={10.1109/TPAMI.2019.2956699}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8918052/"
        },
    ]
    let journals: Publication[] = [
        {
            bibtex: `@ARTICLE{9667788,
                author={Di, Donglin and Zhang, Jun and Lei, Fuqiang and Tian, Qi and Gao, Yue},
                journal={IEEE Transactions on Image Processing},
                title={Big-Hypergraph Factorization Neural Network for Survival Prediction From Whole Slide Image},
                year={2022},
                volume={31},
                number={},
                pages={1149-1160},
                doi={10.1109/TIP.2021.3139229}
                }`,
            href: "https://ieeexplore.ieee.org/abstract/document/9667788"
        },
        {
            author: "Yutong Feng, Jianwen Jiang, Mingqian Tang, Rong Jin, Yue Gao",
            title: "Rethinking Supervised Pre-Training for Better Downstream Transferring",
            journal: "International Conference on Learning Representations",
            year: "2022"
        },
        {
            bibtex: `@article{RUAN2021100390,
                title = {Exploring Complex and Heterogeneous Correlations on Hypergraph for the Prediction of Drug-Target Interactions},
                journal = {Patterns},
                volume = {2},
                number = {12},
                pages = {100390},
                year = {2021},
                issn = {2666-3899},
                doi = {https://doi.org/10.1016/j.patter.2021.100390},
                url = {https://www.sciencedirect.com/science/article/pii/S2666389921002579},
                author = {Ding Ruan and Shuyi Ji and Chenggang Yan and Junjie Zhu and Xibin Zhao and Yuedong Yang and Yue Gao and Changqing Zou and Qionghai Dai},
                keywords = {drug discovery, hypergraph, deep learning}
                }`,
            line3: "Patterns, 2021, Cell Press",
            href: "https://www.sciencedirect.com/science/article/pii/S2666389921002579"
        },
        {
            bibtex: `@ARTICLE{9535255,
                author={Ma, Xueqi and Liu, Weifeng and Tian, Qi and Gao, Yue},
                journal={IEEE Transactions on Multimedia},
                title={Learning Representation on Optimized High-Order Manifold for Visual Classification},
                year={2021},
                volume={},
                number={},
                pages={1-1},
                doi={10.1109/TMM.2021.3111500}
                }`,
            href: "https://ieeexplore.ieee.org/document/9535255"
        },
        {
            bibtex: `@inproceedings{li2021event,
                title={Event Stream Super-Resolution via Spatiotemporal Constraint Learning},
                author={Li, Siqi and Feng, Yutong and Li, Yipeng and Jiang, Yu and Zou, Changqing and Gao, Yue},
                booktitle={Proceedings of the IEEE/CVF International Conference on Computer Vision},
                pages={4480--4489},
                year={2021}
                }`,
            href: "https://openaccess.thecvf.com/content/ICCV2021/html/Li_Event_Stream_Super-Resolution_via_Spatiotemporal_Constraint_Learning_ICCV_2021_paper.html"
        },
        {
            bibtex: `@article{cheng2021visual,
                title={Visual Information Quantification for Object Recognition and Retrieval},
                author={Cheng, JiaLiang and Bie, Lin and Zhao, XiBin and Gao, Yue},
                journal={Science China Technological Sciences},
                volume={64},
                number={12},
                pages={2618--2626},
                year={2021},
                publisher={Springer}
                }`,
            href: "https://link.springer.com/article/10.1007/s11431-021-1930-8"
        },
        {
            bibtex: `@ARTICLE{9442912,
                author={Bai, Junjie and Gong, Biao and Zhao, Yining and Lei, Fuqiang and Yan, Chenggang and Gao, Yue},
                journal={IEEE Transactions on Image Processing},
                title={Multi-Scale Representation Learning on Hypergraph for 3D Shape Retrieval and Recognition},
                year={2021},
                volume={30},
                number={},
                pages={5327-5338},
                doi={10.1109/TIP.2021.3082765}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9442912/"
        },

        {
            bibtex: `@ARTICLE{9525190,
                author={Hu, Youpeng and Li, Xunkai and Wang, Yujie and Wu, Yixuan and Zhao, Yining and Yan, Chenggang and Yin, Jian and Gao, Yue},
                journal={IEEE Transactions on Knowledge and Data Engineering},
                title={Adaptive Hypergraph Auto-Encoder for Relational Data Clustering},
                year={2021},
                volume={},
                number={},
                pages={1-1},
                doi={10.1109/TKDE.2021.3108192}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9525190/"
        },
        {
            bibtex: `@inproceedings{wang20213dioumatch,
                title={3DIoUMatch: Leveraging IoU Prediction for Semi-Supervised 3D Object Detection},
                author={Wang, He and Cong, Yezhen and Litany, Or and Gao, Yue and Guibas, Leonidas J},
                booktitle={Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition},
                pages={14615--14624},
                year={2021}
                }`,
            href: "https://openaccess.thecvf.com/content/CVPR2021/papers/Wang_3DIoUMatch_Leveraging_IoU_Prediction_for_Semi-Supervised_3D_Object_Detection_CVPR_2021_paper.pdf"
        },
        {
            bibtex: `@inproceedings{zhang2021view,
                title={View-Guided Point Cloud Completion},
                author={Zhang, Xuancheng and Feng, Yutong and Li, Siqi and Zou, Changqing and Wan, Hai and Zhao, Xibin and Guo, Yandong and Gao, Yue},
                booktitle={Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition},
                pages={15890--15899},
                year={2021}
                }`,
            href: "https://openaccess.thecvf.com/content/CVPR2021/papers/Zhang_View-Guided_Point_Cloud_Completion_CVPR_2021_paper.pdf"
        },
        {
            bibtex: `@ARTICLE{9535255,
                author={Ma, Xueqi and Liu, Weifeng and Tian, Qi and Gao, Yue},
                journal={IEEE Transactions on Multimedia},
                title={Learning Representation on Optimized High-Order Manifold for Visual Classification},
                year={2021},
                volume={},
                number={},
                pages={1-1},
                doi={10.1109/TMM.2021.3111500}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9535255/"
        },
        {
            bibtex: `@article{di2021hypergraph,
                title={Hypergraph learning for identification of COVID-19 with CT imaging},
                author={Di, Donglin and Shi, Feng and Yan, Fuhua and Xia, Liming and Mo, Zhanhao and Ding, Zhongxiang and Shan, Fei and Song, Bin and Li, Shengrui and Wei, Ying and others},
                journal={Medical Image Analysis},
                volume={68},
                pages={101910},
                year={2021},
                publisher={Elsevier}
                }`,
            href: "https://www.sciencedirect.com/science/article/pii/S1361841520302747/"
        },
        {
            bibtex: `@ARTICLE{8876655,
                author={Yang, Yang and Fan, Dandan and Du, Shaoyi and Wang, Muyi and Chen, Badong and Gao, Yue},
                journal={IEEE Transactions on Cybernetics},
                title={Point Set Registration With Similarity and Affine Transformations Based on Bidirectional KMPE Loss},
                year={2021},
                volume={51},
                number={3},
                pages={1678-1689},
                doi={10.1109/TCYB.2019.2944171}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8876655/"
        },
        {
            bibtex: `@ARTICLE{9350205,
                author={Wan, Teng and Du, Shaoyi and Cui, Wenting and Yao, Runzhao and Ge, Yuyan and Li, Ce and Gao, Yue and Zheng, Nanning},
                journal={IEEE Transactions on Neural Networks and Learning Systems},
                title={RGB-D Point Cloud Registration Based on Salient Object Detection},
                year={2021},
                volume={},
                number={},
                pages={1-13},
                doi={10.1109/TNNLS.2021.3053274}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9350205/"
        },
        {
            bibtex: `@ARTICLE{9151281,
                author={Ji, Shuyi and Zhang, Zizhao and Ying, Shihui and Wang, Liejun and Zhao, Xibin and Gao, Yue},
                journal={IEEE Transactions on Cybernetics},
                title={Kullback-Leibler Divergence Metric Learning},
                year={2020},
                volume={},
                number={},
                pages={1-12},
                doi={10.1109/TCYB.2020.3008248}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9151281/"
        },
        {
            bibtex: `@ARTICLE{9159849,
                author={Gong, Biao and Yan, Chenggang and Bai, Junjie and Zou, Changqing and Gao, Yue},
                journal={IEEE Transactions on Image Processing},
                title={Hamming Embedding Sensitivity Guided Fusion Network for 3D Shape Representation},
                year={2020},
                volume={29},
                number={},
                pages={8381-8390},
                doi={10.1109/TIP.2020.3013138}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9159849/"
        },
        {
            bibtex: `@article{li2020future,
                title={Future vehicles: learnable wheeled robots},
                author={Li, Deyi and Ma, Nan and Gao, Yue},
                journal={Science China Information Sciences},
                volume={63},
                number={9},
                pages={1--8},
                year={2020},
                publisher={Springer}
                }`,
            href: "https://link.springer.com/article/10.1007/s11432-019-2787-2"
        },
        {
            bibtex: `@ARTICLE{8323195,
                author={Zhao, Sicheng and Ding, Guiguang and Gao, Yue and Zhao, Xin and Tang, Youbao and Han, Jungong and Yao, Hongxun and Huang, Qingming},
                journal={IEEE Transactions on Affective Computing},
                title={Discrete Probability Distribution Prediction of Image Emotions with Shared Sparse Learning},
                year={2020},
                volume={11},
                number={4},
                pages={574-587},
                doi={10.1109/TAFFC.2018.2818685}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8323195/"
        },
        {
            bibtex: `@ARTICLE{9129791,
                author={Nie, Wei-Zhi and Liu, An-An and Zhao, Sicheng and Gao, Yue},
                journal={IEEE Transactions on Cybernetics},
                title={Deep Correlated Joint Network for 2-D Image-Based 3-D Model Retrieval},
                year={2020},
                volume={},
                number={},
                pages={1-10},
                doi={10.1109/TCYB.2020.2995415}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8676050/"
        },
        {
            bibtex: `@ARTICLE{9170841,
                author={Zhu, Xiaofeng and Zhang, Shichao and Zhu, Yonghua and Zhu, Pengfei and Gao, Yue},
                journal={IEEE Transactions on Knowledge and Data Engineering},
                title={Unsupervised Spectral Feature Selection with Dynamic Hyper-graph Learning},
                year={2020},
                volume={},
                number={},
                pages={1-1},
                doi={10.1109/TKDE.2020.3017250}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/9170841/"
        },
        {
            bibtex: `@inproceedings{10.1145/3394171.3413718,
                author = {Zhu, Junjie and Luo, Bingjun and Zhao, Sicheng and Ying, Shihui and Zhao, Xibin and Gao, Yue},
                title = {IExpressNet: Facial Expression Recognition with Incremental Classes},
                year = {2020},
                isbn = {9781450379885},
                publisher = {Association for Computing Machinery},
                address = {New York, NY, USA},
                url = {https://doi.org/10.1145/3394171.3413718},
                doi = {10.1145/3394171.3413718},
                booktitle = {Proceedings of the 28th ACM International Conference on Multimedia},
                pages = {2899–2908},
                numpages = {10},
                keywords = {class incremental, facial expression recognition},
                location = {Seattle, WA, USA},
                series = {MM '20}}`,
            href: "https://dl.acm.org/doi/abs/10.1145/3394171.3413718/"
        },
        {
            bibtex: `@inproceedings{di2020ranking,
                title={Ranking-Based Survival Prediction on Histopathological Whole-Slide Images},
                author={Di, Donglin and Li, Shengrui and Zhang, Jun and Gao, Yue},
                booktitle={International Conference on Medical Image Computing and Computer-Assisted Intervention},
                pages={428--438},
                year={2020},
                organization={Springer}
                }`,
            href: "https://link.springer.com/chapter/10.1007/978-3-030-59722-1_41"
        },
        {
            bibtex: `@inproceedings{10.1145/3394486.3403253,
                author = {Ji, Shuyi and Feng, Yifan and Ji, Rongrong and Zhao, Xibin and Tang, Wanwan and Gao, Yue},
                title = {Dual Channel Hypergraph Collaborative Filtering},
                year = {2020},
                isbn = {9781450379984},
                publisher = {Association for Computing Machinery},
                address = {New York, NY, USA},
                url = {https://doi.org/10.1145/3394486.3403253},
                doi = {10.1145/3394486.3403253},
                booktitle = {Proceedings of the 26th ACM SIGKDD International Conference on Knowledge Discovery & Data Mining},
                pages = {2020–2029},
                numpages = {10},
                keywords = {collaborative filtering, hypergraph, dual channel},
                location = {Virtual Event, CA, USA},
                series = {KDD '20}}`,
            href: "https://dl.acm.org/doi/abs/10.1145/3394486.3403253"
        },
        {
            bibtex: `@inproceedings{zhang2020hypergraph,
                title={Hypergraph Label Propagation Network},
                author={Zhang, Yubo and Wang, Nan and Chen, Yufeng and Zou, Changqing and Wan, Hai and Zhao, Xinbin and Gao, Yue},
                booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
                volume={34},
                number={04},
                pages={6885--6892},
                year={2020}
                }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/6170"
        },
        {
            bibtex: `@inproceedings{jiang2020divide,
                title={Divide and Conquer: Question-Guided Spatio-Temporal Contextual Attention for Video Question Answering},
                author={Jiang, Jianwen and Chen, Ziqiang and Lin, Haojie and Zhao, Xibin and Gao, Yue},
                booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
                volume={34},
                number={07},
                pages={11101--11108},
                year={2020}
                }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/6766"
        },
        {
            bibtex: `@inproceedings{li2020attention,
                   title={Attention-Based Multi-Modal Fusion Network for Semantic Scene Completion},
                author={Li, Siqi and Zou, Changqing and Li, Yipeng and Zhao, Xibin and Gao, Yue},
                booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
                volume={34},
                number={07},
                pages={11402--11409},
                year={2020}
                }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/6803"
        },
        {
            bibtex: `@ARTICLE{8825837,
                author={Dong, Pei and Guo, Yanrong and Gao, Yue and Liang, Peipeng and Shi, Yonghong and Wu, Guorong},
                journal={IEEE Transactions on Neural Networks and Learning Systems},
                title={Multi-Atlas Segmentation of Anatomical Brain Structures Using Hierarchical Hypergraph Learning},
                year={2020},
                volume={31},
                number={8},
                pages={3061-3072},
                doi={10.1109/TNNLS.2019.2935184}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8825837/"
        },
        {
            bibtex: `@inproceedings{feng2019hypergraph,
                title={Hypergraph Neural Networks},
                author={Feng, Yifan and You, Haoxuan and Zhang, Zizhao and Ji, Rongrong and Gao, Yue},
                booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
                volume={33},
                number={01},
                pages={3558--3565},
                year={2019}
                }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/4235"
        },
        {
            bibtex: `@ARTICLE{8590732,
                author={Jin, Taisong and Ji, Rongrong and Gao, Yue and Sun, Xiaoshuai and Zhao, Xibin and Tao, Dacheng},
                journal={IEEE Transactions on Image Processing},
                title={Correntropy-Induced Robust Low-Rank Hypergraph},
                year={2019},
                volume={28},
                number={6},
                pages={2755-2769},
                doi={10.1109/TIP.2018.2889960}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8590732/"
        },
        {
            bibtex: `@ARTICLE{8684782,
                author={Wang, Nan and Zhang, Zizhao and Zhao, Xibin and Miao, Quan and Ji, Rongrong and Gao, Yue},
                journal={IEEE Transactions on Industrial Electronics},
                title={Exploring High-Order Correlations for Industry Anomaly Detection},
                year={2019},
                volume={66},
                number={12},
                pages={9682-9691},
                doi={10.1109/TIE.2019.2907441}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8684782/"
        },
        {
            bibtex: `@ARTICLE{8478794,
                author={Shi, Heyuan and Zhang, Yubo and Zhang, Zizhao and Ma, Nan and Zhao, Xibin and Gao, Yue and Sun, Jiaguang},
                journal={IEEE Transactions on Neural Networks and Learning Systems},
                title={Hypergraph-Induced Convolutional Networks for Visual Classification},
                year={2019},
                volume={30},
                number={10},
                pages={2963-2972},
                doi={10.1109/TNNLS.2018.2869747}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8478794/"
        },
        {
            bibtex: `@ARTICLE{8450030,
                author={Ji, Rongrong and Chen, Fuhai and Cao, Liujuan and Gao, Yue},
                journal={IEEE Transactions on Multimedia},
                title={Cross-Modality Microblog Sentiment Prediction via Bi-Layer Multimodal Hypergraph Learning},
                year={2019},
                volume={21},
                number={4},
                pages={1062-1075},
                doi={10.1109/TMM.2018.2867718}}`,
            href: " https://ieeexplore.ieee.org/abstract/document/8450030/"
        },
        {
            bibtex: `@inproceedings{li2019universal,
                title={Universal Perturbation Attack Against Image Retrieval},
                author={Li, Jie and Ji, Rongrong and Liu, Hong and Hong, Xiaopeng and Gao, Yue and Tian, Qi},
                booktitle={Proceedings of the IEEE/CVF International Conference on Computer Vision},
                pages={4899--4908},
                year={2019}
                }`,
            href: "https://openaccess.thecvf.com/content_ICCV_2019/html/Li_Universal_Perturbation_Attack_Against_Image_Retrieval_ICCV_2019_paper.html"
        },
        {
            bibtex: `@inproceedings{liu2019universal,
                title={Universal Adversarial Perturbation Via Prior Driven Uncertainty Approximation},
                author={Liu, Hong and Ji, Rongrong and Li, Jie and Zhang, Baochang and Gao, Yue and Wu, Yongjian and Huang, Feiyue},
                booktitle={Proceedings of the IEEE/CVF International Conference on Computer Vision},
                pages={2941--2949},
                year={2019}
                }`,
            href: "https://openaccess.thecvf.com/content_ICCV_2019/html/Liu_Universal_Adversarial_Perturbation_via_Prior_Driven_Uncertainty_Approximation_ICCV_2019_paper.html"
        },
        {
            bibtex: `@ARTICLE{8424480,
                author={Zhang, Zizhao and Lin, Haojie and Zhao, Xibin and Ji, Rongrong and Gao, Yue},
                journal={IEEE Transactions on Image Processing},
                title={Inductive Multi-Hypergraph Learning and Its Application on View-Based 3D Object Classification},
                year={2018},
                volume={27},
                number={12},
                pages={5957-5968},
                doi={10.1109/TIP.2018.2862625}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8424480/"
        },
        {
            bibtex: `@article{10.1145/3233184,
                author = {Zhao, Sicheng and Gholaminejad, Amir and Ding, Guiguang and Gao, Yue and Han, Jungong and Keutzer, Kurt},
                title = {Personalized Emotion Recognition by Personality-Aware High-Order Learning of Physiological Signals},
                year = {2019},
                issue_date = {February 2019},
                publisher = {Association for Computing Machinery},
                address = {New York, NY, USA},
                volume = {15},
                number = {1s},
                issn = {1551-6857},
                url = {https://doi.org/10.1145/3233184},
                doi = {10.1145/3233184},
                journal = {ACM Transactions on Multimedia Computing, Communications, and Applications},
                month = jan,
                articleno = {14},
                numpages = {18},
                }`,
            href: "https://dl.acm.org/doi/abs/10.1145/3233184/"
        },
        {
            bibtex: `@article{10.1145/3332374,
                author = {Zhu, Junjie and Wei, Yuxuan and Feng, Yifan and Zhao, Xibin and Gao, Yue},
                title = {Physiological Signals-Based Emotion Recognition via High-Order Correlation Learning},
                year = {2019},
                issue_date = {January 2020},
                publisher = {Association for Computing Machinery},
                address = {New York, NY, USA},
                volume = {15},
                number = {3s},
                issn = {1551-6857},
                url = {https://doi.org/10.1145/3332374},
                doi = {10.1145/3332374},
                journal = {ACM Transactions on Multimedia Computing, Communications, and Applications},
                month = dec,
                articleno = {95},
                numpages = {18},
                }`,
            href: "https://dl.acm.org/doi/abs/10.1145/3332374/"
        },
        {
            bibtex: `@inproceedings{ijcai2019-366,
                title     = {Dynamic Hypergraph Neural Networks},
                author    = {Jiang, Jianwen and Wei, Yuxuan and Feng, Yifan and Cao, Jingxuan and Gao, Yue},
                booktitle = {Proceedings of the 28th International Joint Conference on Artificial Intelligence},
                publisher = {International Joint Conferences on Artificial Intelligence Organization},
                pages     = {2635--2641},
                year      = {2019},
                month     = {7},
                doi       = {10.24963/ijcai.2019/366},
                url       = {https://doi.org/10.24963/ijcai.2019/366},
                }
`,
            href: "https://doi.org/10.24963/ijcai.2019/366"
        },
        {
            bibtex: `@inproceedings{you2019pvrnet,
                title={PVRNet: Point-View Relation Neural Network for 3D Shape Recognition},
                author={You, Haoxuan and Feng, Yifan and Zhao, Xibin and Zou, Changqing and Ji, Rongrong and Gao, Yue},
                booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
                volume={33},
                number={01},
                pages={9119--9126},
                year={2019}
                }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/4945"
        },
        {
            bibtex: `@inproceedings{jiang2019mlvcnn,
                title={MLVCNN: Multi-Loop-View Convolutional Neural Network for 3D Shape Retrieval},
                author={Jiang, Jianwen and Bao, Di and Chen, Ziqiang and Zhao, Xibin and Gao, Yue},
                booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
                volume={33},
                number={01},
                pages={8513--8520},
                year={2019}
                }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/4869"
        },
        {
            bibtex: `@inproceedings{feng2019meshnet,
               title={MeshNet: Mesh Neural Network for 3D Shape Representation},
               author={Feng, Yutong and Feng, Yifan and You, Haoxuan and Zhao, Xibin and Gao, Yue},
               booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
               volume={33},
               number={01},
               pages={8279--8286},
               year={2019}
             }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/4840"
        },

        {
            bibtex: `@inproceedings{huang2019deepccfv,
               title={DeepCCFV: Camera Constraint-Free Multi-View Convolutional Neural Network for 3D Object Retrieval},
               author={Huang, Zhengyue and Zhao, Zhehui and Zhou, Hengguang and Zhao, Xibin and Gao, Yue},
               booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
               volume={33},
               number={01},
               pages={8505--8512},
               year={2019}
             }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/4868"
        },
        {
            bibtex: `@ARTICLE{8082103,
               author={Zhao, Sicheng and Gao, Yue and Ding, Guiguang and Chua, Tat-Seng},
               journal={IEEE Transactions on Cybernetics},
               title={Real-Time Multimedia Social Event Detection in Microblog},
               year={2018},
               volume={48},
               number={11},
               pages={3218-3231},
               doi={10.1109/TCYB.2017.2762344}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8082103/"
        },
        {
            bibtex: `@ARTICLE{8023775,
                author={Zhao, Xibin and Wang, Nan and Zhang, Yubo and Du, Shaoyi and Gao, Yue and Sun, Jiaguang},
                journal={IEEE Transactions on Neural Networks and Learning Systems},
                title={Beyond Pairwise Matching: Person Reidentification via High-Order Relevance Learning},
                year={2018},
                volume={29},
                number={8},
                pages={3701-3714},
                doi={10.1109/TNNLS.2017.2736640}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8023775/"
        },
        {
            bibtex: `@ARTICLE{7744512,
               author={Zhao, Sicheng and Yao, Hongxun and Gao, Yue and Ding, Guiguang and Chua, Tat-Seng},
               journal={IEEE Transactions on Affective Computing},
               title={Predicting Personalized Image Emotion Perceptions in Social Networks},
               year={2018},
               volume={9},
               number={4},
               pages={526-540},
               doi={10.1109/TAFFC.2016.2628787}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/7744512?/"
        },
        {
            bibtex: `@INPROCEEDINGS{8578133,
                author={Feng, Yifan and Zhang, Zizhao and Zhao, Xibin and Ji, Rongrong and Gao, Yue},
                booktitle={2018 IEEE/CVF Conference on Computer Vision and Pattern Recognition},
                title={GVCNN: Group-View Convolutional Neural Networks for 3D Shape Recognition},
                year={2018},
                volume={},
                number={},
                pages={264-272},
                doi={10.1109/CVPR.2018.00035}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8578133"
        },
        {
            bibtex: `@inproceedings{10.1145/3240508.3240702,
                author = {You, Haoxuan and Feng, Yifan and Ji, Rongrong and Gao, Yue},
                title = {PVNet: A Joint Convolutional Network of Point Cloud and Multi-View for 3D Shape Recognition},
                year = {2018},
                isbn = {9781450356657},
                publisher = {Association for Computing Machinery},
                address = {New York, NY, USA},
                url = {https://doi.org/10.1145/3240508.3240702},
                doi = {10.1145/3240508.3240702},
                booktitle = {Proceedings of the 26th ACM International Conference on Multimedia},
                pages = {1310–1318},
                numpages = {9},
                keywords = {point cloud, 3d shape recognition, point-view net, multi-view},
                location = {Seoul, Republic of Korea},
                series = {MM '18}
                }`,
            href: "https://dl.acm.org/doi/abs/10.1145/3240508.3240702/"
        },
        {
            bibtex: `@inproceedings{zhang2018emd,
                title={EMD Metric Learning},
                author={Zhang, Zizhao and Zhang, Yubo and Zhao, Xibin and Gao, Yue},
                booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
                volume={32},
                number={1},
                year={2018}
                }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/11703"
        },
        {
            bibtex: `@inproceedings{huang2018energy,
                title={Energy-Efficient Automatic Train Driving by Learning Driving Patterns},
               author={Huang, Jin and Gao, Yue and Lu, Sha and Zhao, Xibin and Deng, Yangdong and Gu, Ming},
                booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
                volume={32},
                number={1},
               year={2018}
             }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/11859"
        },
        {
            bibtex: `@inproceedings{zhao2018hypergraph,
                title={Hypergraph Learning With Cost Interval Optimization},
                author={Zhao, Xibin and Wang, Nan and Shi, Heyuan and Wan, Hai and Huang, Jin and Gao, Yue},
                booktitle={Proceedings of the AAAI Conference on Artificial Intelligence},
                volume={32},
                number={1},
                year={2018}
                }`,
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/11761"
        },
        {
            bibtex: `@inproceedings{10.5555/3304889.3305100,
                author = {Zhang, Zizhao and Lin, Haojie and Gao, Yue},
                title = {Dynamic Hypergraph Structure Learning},
                year = {2018},
                isbn = {9780999241127},
                publisher = {AAAI Press},
                booktitle = {Proceedings of the 27th International Joint Conference on Artificial Intelligence},
                pages = {3162–3169},
                numpages = {8},
                location = {Stockholm, Sweden},
                series = {IJCAI'18}
                }`,
            href: "https://dl.acm.org/doi/abs/10.5555/3304889.3305100"
        },
        {
            bibtex: `@inproceedings{10.5555/3304889.3305050,
                author = {Wang, Nan and Zhao, Xibin and Jiang, Yu and Gao, Yue},
                title = {Iterative Metric Learning for Imbalance Data Classification},
                year = {2018},
                isbn = {9780999241127},
                publisher = {AAAI Press},
                booktitle = {Proceedings of the 27th International Joint Conference on Artificial Intelligence},
                pages = {2805–2811},
                numpages = {7},
                location = {Stockholm, Sweden},
                series = {IJCAI'18}
                }`,
            href: "https://dl.acm.org/doi/abs/10.5555/3304889.3305050"
        },
        {
            bibtex: `@inproceedings{zhang2018cross,
               title={Cross Diffusion on Multi-hypergraph for Multi-modal 3D Object Recognition},
               author={Zhang, Zizhao and Lin, Haojie and Zhu, Junjie and Zhao, Xibin and Gao, Yue},
               booktitle={Pacific Rim Conference on Multimedia},
               pages={38--49},
               year={2018},
               organization={Springer}
             }`,
            href: "https://link.springer.com/chapter/10.1007/978-3-030-00776-8_4"
        },
        {
            bibtex: `@INPROCEEDINGS{8500525,
               author={Xu, Guanglin and Du, Shaoyi and Cui, Dixiao and Zhang, Sirui and Chen, Badong and Zhang, Xuetao and Xue, Jianru and Gao, Yue},
               booktitle={2018 IEEE Intelligent Vehicles Symposium (IV)},
               title={Precise Point Set Registration Using Point-to-Plane Distance and Correntropy for LiDAR Based Localization},
               year={2018},
               volume={},
               number={},
               pages={734-739},
               doi={10.1109/IVS.2018.8500525}}`,
            href: "https://ieeexplore.ieee.org/abstract/document/8500525/"
        },
    ]
    transformBibtex(topJournals)
    transformBibtex(journals)
    journals = mergeSort(journals, (a: Publication, b: Publication) => Number(b.year) - Number(a.year))
    const result = books.concat(topJournals).concat(journals)
    processPublications(result)
    return result
}

export default state()