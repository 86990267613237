
import Vue from 'vue'
import {Component} from "vue-property-decorator";
import S404 from "@/views/404.vue";
import Markdown from "@/components/Markdown.vue";
import publications, {Publication} from "@/store/publications";

@Component({
  components: {Markdown, S404}
})
export default class PublicationDetail extends Vue {
  name = 'PublicationDetail'

  get publication(): Publication | undefined {
    return publications.find(v => v.title === this.$route.params.name)
  }
}
